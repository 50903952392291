
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import PreviewHeader from './PreviewHeader';
import PreviewContainerEditableTest from './PreviewContainerEditableTest';
import { ROLES } from '@/config';
import { NeButton } from '@ne/ne-vue2-lib';
import { namespace } from 'vuex-class';
import { GET_EXERCISE_COUNTER } from '@/store/list';
import { isRedactorExamOrNsml } from '@/helpers/roles';
import { FloatingFooter } from '@/views/Generator/TestComposer/components';

const TestStore = namespace('TestStore');
const AppStore = namespace('AppStore');

@Component({
  name: 'PreviewContainer',
  components: {
    FloatingFooter,
    PreviewContainerEditableTest,
    PreviewHeader,
    NeButton
  }
})
export default class PreviewContainer extends Vue {
  @Prop() isTestPreviewOpen: boolean;
  @Prop() step: number;
  @Prop() isFiltersVisible!: boolean;

  @TestStore.Getter(GET_EXERCISE_COUNTER) exerciseCounter!: number;
  @AppStore.State('userRole') userRole: ROLES[];

  get isOnePageViewOnly (): boolean {
    return isRedactorExamOrNsml(this.userRole);
  }

  get scaleFactor (): number {
    if (this.isTestPreviewOpen) return 1;
    return this.isFiltersVisible ? 0.37 : 0.65;
  }

  @Watch('isTestPreviewOpen')
  isPreviewOpenHandler () {
    if (this.isTestPreviewOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'auto';
    }
  }

  @Emit() hidePreview (): void {}
}
