
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { GroupMap } from '@/helpers';
import { TestGroupChangeEvent } from '@/models/tests';
import { NeButton } from '@ne/ne-vue2-lib';

@Component({
  name: 'finalize-test-tabs',
  components: {
    NeButton
  }
})
export default class FinalizeTestTabs extends Vue {
  @Prop() left!: boolean;
  @Prop() variants: any[];
  @Prop() currentGroupIndex!: number;
  @Prop() otherGroupIndex!: number;

  groupMap = GroupMap;

  get displayAddGroup (): boolean {
    return this.variants.length < 8;
  }

  tabLinkClasses (index: number): any[] {
    const isTabLast = index === this.variants.length - 1 && this.variants.length > 1;
    return [
      'gt-finalize-test-tabs__tab',
      'gt-finalize-test-tabs__link',
      {
        'gt-finalize-test-tabs__tab--active': this.currentGroupIndex === index,
        'gt-finalize-test-tabs__tab--disabled': this.otherGroupIndex === index,
        'gt-finalize-test-tabs__tab--last-group': isTabLast
      }
    ];
  }

  changeGroup (index: number) {
    if (index !== this.otherGroupIndex) {
      this.change(index);
    }
  }

  @Emit()
  change (index: number): TestGroupChangeEvent {
    return {
      isLeftPage: this.left,
      index: index
    };
  }

  @Emit()
  add (): void {}

  @Emit()
  remove (): number {
    return this.currentGroupIndex;
  }
}
