
import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import cutTitleMixin from '@/mixins/cutTitleMixin';
import { namespace } from 'vuex-class';
import {
  GET_SCORE_COUNTER,
  GET_TEST_HEADER,
  GET_TEST_TIME,
  GET_TEST_TITLE,
  IS_SUPER_USER,
  VARIANTS_ORDER
} from '@/store/list';
import { Page, TestHeader, TestModel } from '@/models';
import { cloneNoRef, GroupMap } from '@/helpers';
import EditableExercise from '../../../EditableExercise';
import { ratioMap, previewHeaderHeight, previewHeaderHeightEarlyEducation } from '@/config';
import { Scale } from '@/shared/components';
import GtTestPage from '@/shared/components/GtTest/GtTestPage.vue';
import { order } from '@/helpers/order';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'FinalizeEditableTest',
  components: { EditableExercise, Scale, GtTestPage }
})
export default class FinalizeEditableTest extends mixins(cutTitleMixin) {
  @Prop() groupIndex!: number;
  @Prop() test!: TestModel;
  @Prop() isRedactorExamOrNsml!: boolean;

  previewHeaderHeight = previewHeaderHeight
  previewHeaderHeightEarlyEducation = previewHeaderHeightEarlyEducation
  ratioMap = ratioMap;
  scaleFactor = 0.7;
  loadedExercisesCount = 0;

  @AppStore.State('step') currentStep!: any;
  @AppStore.Getter(IS_SUPER_USER) isSuperUser!: boolean;
  @TestStore.Getter(GET_TEST_HEADER) testHeader!: TestHeader;
  @TestStore.Getter(GET_TEST_TITLE) testTitle!: string;
  @TestStore.Getter(GET_TEST_TIME) testTime!: number;
  @TestStore.Getter(GET_SCORE_COUNTER) totalScore!: number;
  @TestStore.Action(VARIANTS_ORDER) variantsOrder: any;

  handleAllExercisesLoad () {
    this.loadedExercisesCount++;
    if (this.loadedExercisesCount === this.flatTestPages.length) {
      this.reorder();
    }
  }

  reorder () {
    this.test.variants[this.groupIndex].pages = order(cloneNoRef(this.test.variants[this.groupIndex].pages), 0, 0, 'none', this.test.earlySchoolEducation);
  }

  get getTestTitle () {
    return this.testTitle;
  }

  get getGroup () {
    return GroupMap.get(this.groupIndex);
  }

  get testPages (): Page[][] {
    return this.test && this.test.variants[this.groupIndex] ? this.test.variants[this.groupIndex].pages : [[]];
  }

  get flatTestPages () {
    return this.testPages.flat();
  }

  @Emit() removeExercise () {}

  isFirst (pageIndex: number, exerciseIndex: number) {
    return pageIndex === 0 && exerciseIndex === 0;
  }

  isLast (pageIndex: number, exerciseIndex: number, groupIndex: number) {
    return (
      pageIndex === this.test.variants[groupIndex].pages.length - 1 &&
      exerciseIndex === this.test.variants[groupIndex].pages[pageIndex].length - 1
    );
  }
}
