
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  GET_EXERCISE_COUNTER,
  GET_PAGE_COUNTER,
  GET_SCORE_COUNTER,
  GET_ATTACHMENT_COUNTER,
  GET_TEST_TIME, IS_SUPER_USER
} from '@/store/list';
import { LocaleMessage, TranslateResult } from 'vue-i18n';
import { TestTypeIdEnum } from '@/models';

const TestStore = namespace('TestStore');
const AppStore = namespace('AppStore');

interface Stats {
  label: string;
  value: number | LocaleMessage;
  isVisible: boolean;
}

@Component({
  name: 'FinalizeStats'
})
export default class FinalizeStats extends Vue {
  @Prop() isRedactorExamOrNsml!: boolean;
  @Prop() testType!: TestTypeIdEnum;

  @TestStore.Getter(GET_PAGE_COUNTER) pageCounter!: number;
  @TestStore.Getter(GET_EXERCISE_COUNTER) exerciseCounter!: number;
  @TestStore.Getter(GET_SCORE_COUNTER) pointsCounter!: number;
  @TestStore.Getter(GET_ATTACHMENT_COUNTER) attachmentsCounter!: number;
  @TestStore.Getter(GET_TEST_TIME) testTime!: any;
  @AppStore.Getter(IS_SUPER_USER) isSuperUser!: boolean;

  get time (): string | TranslateResult {
    return this.testTime ? `${this.testTime} min` : this.$t('FINALIZE_HEADER.no_time');
  }

  get stats (): Stats[] {
    return [
      {
        label: this.$tc('FINALIZE_HEADER.counter.pages'),
        value: this.pageCounter,
        isVisible: !this.isRedactorExamOrNsml
      },
      {
        label: this.$tc('FINALIZE_HEADER.counter.exercises'),
        value: this.exerciseCounter,
        isVisible: true
      },
      {
        label: this.$tc('FINALIZE_HEADER.counter.scores'),
        value: this.pointsCounter,
        isVisible: !this.isWorkCard
      },
      {
        label: this.$tc('FINALIZE_HEADER.counter.attachments'),
        value: this.attachmentsCounter,
        isVisible: true
      },
      {
        label: this.$tc('FINALIZE_HEADER.header_time'),
        value: this.time,
        isVisible: !this.isWorkCard
      }
    ].filter(item => item.isVisible);
  }

  get isWorkCard (): boolean {
    return this.testType === TestTypeIdEnum.WORK_CARD;
  }
}
