
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  GET_EXERCISE_COUNTER,
  GET_PAGE_COUNTER,
  GET_SCORE_COUNTER,
  GET_ATTACHMENT_COUNTER,
  GET_TEST_TIME, IS_SUPER_USER
} from '@/store/list';
import { NeIconButton } from '@ne/ne-vue2-lib';
import { Tooltip } from '@/shared/components';
import { TestModel, TestTypeIdEnum } from '@/models';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');
@Component({
  name: 'PreviewHeader',
  components: {
    NeIconButton,
    Tooltip
  }
})
export default class PreviewHeader extends Vue {
  @AppStore.Getter(IS_SUPER_USER) isSuperUser!: boolean;
  @TestStore.Getter(GET_PAGE_COUNTER) pageCounter!: number;
  @TestStore.Getter(GET_EXERCISE_COUNTER) exerciseCounter!: number;
  @TestStore.Getter(GET_SCORE_COUNTER) pointsCounter!: number;
  @TestStore.Getter(GET_ATTACHMENT_COUNTER) attachmentsCounter!: number;
  @TestStore.Getter(GET_TEST_TIME) testTime!: number;
  @TestStore.State('currentTest') currentTest!: TestModel;

  @Prop() isOnePageViewOnly: boolean;
  @Prop() isPreviewOpen: boolean;

  testTypeIdEnum = TestTypeIdEnum;

  @Emit()
  close (): void {}
}
