
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  GET_ANSWER_CARD_NOT_DOWNLOADABLE,
  GET_EXERCISE_COUNTER,
  RECALCULATE_TEST_VARIANTS,
  SAVE_CHANGES_IN_TEST,
  SAVE_NEW_TEST,
  SET_ANSWER_CARD_NOT_DOWNLOADABLE,
  SET_CURRENT_STEP,
  SET_TEST_VALIDATION_ACTIVE,
  UPDATE_TEST_BEFORE_PDF,
  USER_NOTIFY
} from '@/store/list';
import { FileIcon, LoadingBlend, ValidationTooltip } from '@/shared/components';
import { DIRECTIONS, ROLES } from '@/config';
import { LayoutModel, MenuModel } from '@/store/layout-store/types';
import { AdditionalOptionsType, TestAttachment, TestModel, TestTypeIdEnum } from '@/models';
import { mixins } from 'vue-class-component';
import getIconTypeMixin from '@/mixins/getIconTypeMixin';
import { NeAppWrapper, NeButton, NeSpinner, NeTooltip } from '@ne/ne-vue2-lib';
import ClickOutside from '@/directives/click-outside';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { contentSetInfo } from '@/helpers/content-set-info';
import { testActivityInfo } from '@/helpers/activity-info';
import { reorderTestExercises } from '@/helpers/order';
import { RouteName } from '@/router/models';
import { handleTestTypeChange } from '@/helpers/test-helper';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const LayoutStore = namespace('LayoutStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'FloatingFooter',
  components: {
    FileIcon,
    ValidationTooltip,
    NeSpinner,
    NeButton,
    NeAppWrapper,
    LoadingBlend,
    NeTooltip
  },
  directives: {
    ClickOutside
  }
})
export default class FloatingFooter extends mixins(getIconTypeMixin) {
  @Prop() isTestChanged: boolean;
  @Prop({ default: false }) isTestPreview: boolean;
  @PropSync('recalculateAlertDisabled') isRecalculateAlertDisabled: boolean;

  isSavingCompleted: boolean = true;
  testTypeIdEnum = TestTypeIdEnum;

  @AppStore.State('step') currentStep!: number;
  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('userRole') userRole: ROLES[];
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @TestStore.State('currentTest') currentTest!: TestModel;
  @TestStore.Getter(GET_EXERCISE_COUNTER) exerciseCounter!: number;
  @TestStore.Getter(GET_ANSWER_CARD_NOT_DOWNLOADABLE) isAnswerCardNotDownloadable!: boolean;
  @LayoutStore.State('menu') menu!: MenuModel;
  @LayoutStore.State('layout') layout: LayoutModel;

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get attachments (): TestAttachment[] {
    return this.currentTest.attachments || [];
  }

  get validationErrors (): string[] {
    const messages: string[] = [];
    if (!this.currentTest.title || this.currentTest.title.length === 0) {
      messages.push(this.getError('empty_title'));
    } else if (!this.currentTest.title || this.currentTest.title.length > 128) {
      messages.push(this.$tc('FINALIZE_HEADER.test_title_length_warning') + '.');
    }
    return messages;
  }

  get isTestValid (): boolean {
    return this.validationErrors.length === 0;
  }

  get isRedactorExam (): boolean {
    return this.userRole.includes(ROLES.REDACTOR_EXAM);
  }

  @AppStore.Mutation(SET_CURRENT_STEP) changeCurrentStep!: any;
  @AppStore.Action(USER_NOTIFY) notify: any;
  @TestStore.Action(RECALCULATE_TEST_VARIANTS) recalculateTestVariants!: (shouldLeaveOneGroupOnly: boolean) => Promise<void>;
  @TestStore.Mutation(SET_TEST_VALIDATION_ACTIVE) setTestValidationActive!: any;
  @TestStore.Mutation(SET_ANSWER_CARD_NOT_DOWNLOADABLE) setAnswerCardDownloadable: any;
  @TestStore.Action(SAVE_NEW_TEST) saveNewTest!: any;
  @TestStore.Action(SAVE_CHANGES_IN_TEST) saveChangesInTest!: any;
  @TestStore.Action(UPDATE_TEST_BEFORE_PDF) updateTestBeforePdf!: any;

  getError (key: string): string {
    return this.$tc(`TEST_COMPOSER.validation_messages.${key}`) + '.';
  }

  getIconType (filename: string): string {
    const splittedFilename = filename.split('.');
    return splittedFilename[splittedFilename.length - 1];
  }

  scrollToTestTitle (): void {
    const titleElement = document.getElementById('gt-finalize-header__title');
    if (titleElement) {
      titleElement.scrollIntoView(false);
    }
  }

  get testCreationDate (): string {
    const date = new Date();
    return `${date.toLocaleDateString().split('.').reverse().join('-')} ${date.toLocaleTimeString()}`;
  }

  gtmTestCreate () {
    setTimeout(() => {
      this.$piwik.push({
        bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
        c_log_level: CLogLevel.CONTENT,
        chapter: null,
        content_author: this.currentTest.author,
        content_difficulty: null,
        content_id: this.currentTest.id ? this.currentTest.id.toString() : null,
        content_name: this.currentTest.title,
        content_set: contentSetInfo(this.currentTest.variants),
        content_status: testActivityInfo(this.currentTest.published),
        content_subtype: null,
        content_type: ContentType.TEST,
        content_version: this.currentTest.dateModify ?? this.testCreationDate,
        education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        event: EventName.CREATE,
        method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
        page_path: this.$route.path,
        section: null,
        subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
      });
    });
  }

  editTestGtmPush (test: TestModel): void {
    setTimeout(() => {
      this.$piwik.push({
        event: EventName.EDIT,
        c_log_level: CLogLevel.METHOD,
        subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT),
        method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
        education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
        chapter: null,
        section: null,
        content_type: ContentType.TEST,
        content_name: test.title ?? null,
        content_status: testActivityInfo(test.published),
        content_version: test.dateModify,
        content_author: test.author,
        content_difficulty: null,
        content_subtype: null,
        page_path: this.$route.path,
        content_set: contentSetInfo(test.variants),
        content_id: test.id ? test.id.toString() : null
      });
    });
  }

  saveTest () {
    if (this.isTestValid) {
      this.setTestValidationActive(false);
      this.saveAndExit();
    } else {
      this.setTestValidationActive(true);
      this.scrollToTestTitle();
    }
  }

  async saveHandler (): Promise<void> {
    this.isSavingCompleted = false;
    const routeName = this.$route.name;
    if (routeName === 'test-editor' || this.currentTest.id) {
      this.editTestGtmPush(this.currentTest);
      await this.saveChangesInTest();
    } else if (routeName === RouteName.TEST_COMPOSER) {
      this.gtmTestCreate();
      await this.saveNewTest();
    }
    this.isSavingCompleted = true;
    this.notify({
      msg: `<div>
            <p>${this.$tc('NOTIFICATIONS.test_saved')}</p>
      </div>`,
      type: 'success',
      timeInMs: 5000
    });
  }

  @Emit() cancel () {}

  @Emit() save () {}

  @Emit() openPreview () {
    const routeName = this.$route.name as string;
    this.$router.push({ name: routeName, hash: '#testPreviewOpened' });
  }

  @Emit()
  async saveAndExit (): Promise<void> {
    await this.saveHandler();
    this.$router.push({ name: RouteName.TEST_DATABASE});
  }

  async onDownloadDropdownOpen (): Promise<void> {
    if (this.isTestValid && this.isTestChanged) {
      await this.saveHandler();
      this.save();
    }
  }

  goToStep1 () {
    this.isTestPreview ? this.cancel() : this.changeCurrentStep(1);
  }

  @Emit()
  displayNoExerciseModal (): void {}

  @Emit()
  onTestRecalculation (): void {}



  async goToStep2 () {
    if (this.exerciseCounter > 0) {
      if (this.isTestPreview) {
        this.cancel();
      }
      const shouldLeaveOneGroupOnly = this.currentTest.generatorTestTypeId === TestTypeIdEnum.WORK_CARD;
      await this.recalculateTestVariants(shouldLeaveOneGroupOnly);
      this.onTestRecalculation();
      this.setAnswerCardDownloadable();
      reorderTestExercises(this.currentTest.variants, {
        exerciseIndex: 0,
        pageIndex: 0,
        direction: DIRECTIONS.NONE,
        doSwap: false
      },
      this.currentTest.earlySchoolEducation,
      this.isRedactorExam
      );
      if (!this.isRecalculateAlertDisabled) this.notify(this.$tc('NOTIFICATIONS.groups_were_generated'));
      this.isRecalculateAlertDisabled = false;
      this.changeCurrentStep(2);
    } else {
      this.displayNoExerciseModal();
    }
  }

  async updateTestBeforeDownload (redirectURL: string) {
    if (this.exerciseCounter > 0 && this.currentStep === 2) {
      await this.updateTestBeforePdf();
      window.location.href = redirectURL;
    }
  }
}
