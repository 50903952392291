
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  GET_TEST_TIME,
  GET_TEST_TITLE,
  IS_SUPER_USER,
  SET_TEST_VALIDATION_ACTIVE,
  UPDATE_TEST_TITLE
} from '@/store/list';
import { TestModel, TestType, TestTypeIdEnum } from '@/models';
import { ROLES } from '@/config';
import { NeCheckbox, NeFieldValidator, NeSelect } from '@ne/ne-vue2-lib';
import { TextField } from '@/shared/components';
import { ValidationRule } from '@ne/ne-vue2-lib/types/types';
import { debounce } from '@/helpers/debounce';
import { isRedactorGroup, isTeacherGroup } from '@/helpers/roles';
import TemplatePicker
  from '@/views/Generator/ExerciseComposer/components/ExerciseContent/TemplatePicker/TemplatePicker.vue';
import { TranslateResult } from 'vue-i18n';
import { getTestTypeName } from '@/helpers';
import { handleTestTypeChange } from '@/helpers/test-helper';

const TestStore = namespace('TestStore');
const AppStore = namespace('AppStore');

@Component({
  name: 'FinalizeHeader',
  components: {
    TemplatePicker,
    NeCheckbox,
    TextField,
    NeFieldValidator,
    NeSelect
  }
})
export default class FinalizeHeader extends Vue {
  @Prop() testTypes: TestType[];
  testTitle = '';
  testTypeIdEnum = TestTypeIdEnum;

  @AppStore.State('userRole') userRole: ROLES[];
  @TestStore.State('currentTest') test!: TestModel;
  @TestStore.State('testValidationActive') testValidationActive!: TestModel;
  @TestStore.Getter(GET_TEST_TITLE) testTitleGetter!: any;
  @TestStore.Getter(GET_TEST_TIME) testTime!: any;
  @AppStore.Getter(IS_SUPER_USER) isSuperUser!: boolean;

  selectedItemText = '';

  get isRedactorOrAdmin () {
    return isRedactorGroup(this.userRole);
  }

  get isTeacher (): boolean {
    return isTeacherGroup(this.userRole);
  }

  get isTestTitleValidationActive (): boolean {
    return this.testValidationActive && (this.testTitle.length === 0 || this.testTitle.length > 128);
  }

  get titleRules (): ValidationRule[] {
    return [(val: string) => (val && val.length > 0) || this.$tc('FINALIZE_HEADER.test_input_warning') + '.', (val: string) => (val && val.length < 129) || this.$tc('FINALIZE_HEADER.test_title_length_warning') + '.'];
  }

  mounted () {
    this.setTestValidationActive(false);
    this.testTitle = this.testTitleGetter;
  }

  getTeacherTestTypeLabel (id: TestTypeIdEnum): TranslateResult {
    return getTestTypeName(id);
  }

  @TestStore.Mutation(UPDATE_TEST_TITLE) testTitleUpdate!: any;
  @TestStore.Mutation(SET_TEST_VALIDATION_ACTIVE) setTestValidationActive!: any;

  editTestTitle (value: string | null): void {
    this.testTitle = value ? value.toString() : '';
    this.setTestValidationActive(true);
    debounce(this.testTitleUpdate(this.testTitle), 500);
  }

  selectType (item: TestType) {
    this.test.generatorTestTypeId = item.id;
    handleTestTypeChange(item.id, this.test);
    this.selectedItemText = item.text;
  }
}
