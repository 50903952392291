
import { Component, Prop, Vue } from 'vue-property-decorator';
import FinalizeHeader from './FinalizeHeader';
import FinalizeStats from './FinalizeStats';
import FinalizeTestContainer from './FinalizeTestContainer';
import { GET_ANSWER_CARD_NOT_DOWNLOADABLE, IS_SPP, REMOVE_NOTIFICATION, USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';
import {
  TestModel,
  Notification,
  TestVariant,
  TestType,
  AdditionalOptions,
  TestTypeIdEnum,
  AdditionalOptionsType
} from '@/models';
import { cloneNoRef } from '@/helpers';
import { pagesToArray } from '@/helpers/order';
import { LayoutModel } from '@/store/layout-store/types';
import { ROLES } from '@/config';
import { isRedactorExamOrNsml } from '@/helpers/roles';
import { handleTestTypeChange } from '@/helpers/test-helper';

const TestStore = namespace('TestStore');
const AppStore = namespace('AppStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'StepTwo',
  components: {
    FinalizeTestContainer,
    FinalizeStats,
    FinalizeHeader
  }
})
export default class StepTwo extends Vue {
  testTypes: TestType[] = [];
  displayCreateTestStepTwoInfoModal = false;
  isDuplicatedVariantsWarningShown = false;
  duplicatedGroupsError: Notification | null = null;

  @AppStore.Getter(IS_SPP) isSpp: boolean;

  @AppStore.State('userRole') userRole!: ROLES[];
  @TestStore.State('currentTest') currentTest!: TestModel;
  @TestStore.Getter(GET_ANSWER_CARD_NOT_DOWNLOADABLE) isAnswerCardNotDownloadable!: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get hasCookie (): boolean {
    return this.$cookies.get('dln_gt_hide_modal');
  }

  get hasDuplicatedVariantIds () {
    if (this.currentTest.variants.length > 1) {
      const allPages: any[][] = [];
      this.currentTest.variants.forEach((variant: TestVariant) => {
        allPages.push(pagesToArray(cloneNoRef(variant.pages)));
      });
      const mapped = pagesToArray(allPages).map((item: any) => item.variantId);
      return mapped.some((item, idx) => mapped.indexOf(item) !== idx);
    }
    return false;
  }

  get isRedactorExamOrNsml () {
    return isRedactorExamOrNsml(this.userRole);
  }

  getTestTypes (additionalOptions: AdditionalOptions): TestType[] {
    const mappedTypesInArr = Object.entries(additionalOptions.type).map(([key, value]) => {
      return {
        id: Number(key),
        text: value
      };
    });
    return this.isSpp ? mappedTypesInArr.filter((type: TestType) => type.id !== TestTypeIdEnum.WORK_CARD) : mappedTypesInArr;
  }

  getTestTypeId (): number {
    const firstTestTypeId = Object.keys(this.currentTest.additionalOptions?.type as AdditionalOptionsType)[0];
    return this.currentTest.additionalOptions?.type.hasOwnProperty(this.currentTest.generatorTestTypeId) ? this.currentTest.generatorTestTypeId : +firstTestTypeId;
  }

  mounted () {
    this.testTypes = this.getTestTypes(this.currentTest.additionalOptions as AdditionalOptions);
    this.displayCreateTestStepTwoInfoModal = !this.hasCookie;
    this.checkDuplicatedVariants();
    if (this.isAnswerCardNotDownloadable && this.showOndorio) {
      this.notify({ msg: this.$tc('NOTIFICATIONS.answer_card_not_rendered'), type: 'warning', timeInMs: 10_000 });
    }
    const testTypeId = this.getTestTypeId();
    handleTestTypeChange(testTypeId, this.currentTest);

  }

  beforeDestroy () {
    this.removeNotification(this.duplicatedGroupsError);
  }

  @AppStore.Action(USER_NOTIFY) notify: any;
  @AppStore.Mutation(REMOVE_NOTIFICATION) removeNotification: (n: Notification | null) => void;

  closeCreateTestStepTwoInfoModal () {
    this.displayCreateTestStepTwoInfoModal = false;
  }

  async checkDuplicatedVariants () {
    this.removeNotification(this.duplicatedGroupsError);
    if (this.hasDuplicatedVariantIds) {
      this.duplicatedGroupsError = await this.notify({
        msg: this.$tc('NOTIFICATIONS.groups_are_not_different'),
        type: 'warning',
        timeInMs: 3000
      });
    }
  }

  hideDuplicatedVariantsWarning () {
    this.isDuplicatedVariantsWarningShown = false;
  }
}
