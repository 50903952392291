import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import {
  EXERCISES_LIST,
  FILTER_EXERCISES,
  RESET_CACHE,
  RESET_SELECTED_VARIANTS,
  SET_EXERCISE_TO_PREVIEW,
  TOTAL_EXERCISES_FOUND
} from '@/store/list';
import { DEFAULT_EXERCISE_LIMIT } from '@/config';
import { Exercise, ExercisePreViewConfig, ExerciseStoreResponse } from '@/models';
import { ExercisesFilters, ExerciseToAdd } from '@/models/exercises';
import { LoadingStatus } from '@/shared/models/loading';
import { FacetsContainer, SelectedFacet } from '@/models/facets';
import { getQueryParam } from '@/helpers/router';
import { groupBy } from '@/helpers/array-manipulation';
import { namespace } from 'vuex-class';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { LayoutModel } from '@/store/layout-store/types';
import { exerciseActivityInfo } from '@/helpers/activity-info';
import { getExercisePropertyValue } from '@/helpers/gtm';
import { assignNumberOfExerciseToFilters, handleParentNodes, updateLevelFirst } from '@/helpers/facets';
import { Route } from 'vue-router';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const ExerciseStore = namespace('ExerciseStore');
const LayoutStore = namespace('LayoutStore');

const defaultPage = 1;
const defaultSearchText = '';
@Component
export default class ExerciseListMixin extends Vue {
  activeFacets: SelectedFacet[] = [];
  customFilterOptions: object = {};
  exercisePreViewOpened = false;
  isFetchingEnabled = true;
  isSearchCleared = true;
  page = defaultPage;
  pageLimit = DEFAULT_EXERCISE_LIMIT;
  searchText = defaultSearchText;
  sortField: string;
  filtersKey = 0;

  LoadingStatus = LoadingStatus;

  @AppStore.State('categoryKey') categoryKey: string;
  @ExerciseStore.State('exerciseToPreview') exercisePreView!: ExercisePreViewConfig;
  @ExerciseStore.State('cachedExercises') cachedExercises!: Exercise[];
  @ExerciseStore.State('exercises') exercisesResponse!: ExerciseStoreResponse;
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;
  @ExerciseStore.Getter(TOTAL_EXERCISES_FOUND) totalExercises: string;
  @ExerciseStore.Getter(EXERCISES_LIST) exercises: Exercise[];
  @AppStore.State('facets') facets!: FacetsContainer;
  $route: Route;

  get lastPage (): number {
    return Math.ceil(parseInt(this.totalExercises, 10) / this.pageLimit);
  }

  get hideNextButton (): boolean {
    return (
      this.page === this.lastPage &&
      this.exercisePreViewOpened &&
      this.exercises[this.exercises.length - 1].id === this.exercisePreView.exercise.id
    );
  }

  get hidePrevButton (): boolean {
    return this.findIndex() === 0 && this.page === 1;
  }

  @ExerciseStore.Action(FILTER_EXERCISES) filterExercises: (exerciseFilters: ExercisesFilters) => Promise<void>;
  @ExerciseStore.Action(SET_EXERCISE_TO_PREVIEW) previewExercise: any;
  @ExerciseStore.Mutation(RESET_CACHE) resetCache: any;
  @ExerciseStore.Mutation(RESET_SELECTED_VARIANTS) resetSelectedVariants: any;

  @Watch('exercisePreView', { deep: true })
  exercisePreViewChangeHandler (nState: ExercisePreViewConfig) {
    // this is very ugly hack to close modal before opening it agian to maintain corect modal behaviour
    // there is task for refactor exercise preview changing flow (https://jira.nowaera.pl/browse/GT-3756) if its not done kill the pm or po and than do it ;)
    this.exercisePreViewOpened = false;
    this.$nextTick(() => {
      this.exercisePreViewOpened = nState.isOpened;
    });
  }

  created () {
    this.resetSelectedVariants();
    this.getQueryParams();
  }

  addToSetGtmPush (exerciseToAdd: ExerciseToAdd, cLogLevel: CLogLevel = CLogLevel.CONTENT): void {
    this.$piwik.push({
      bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
      c_log_level: cLogLevel,
      chapter: exerciseToAdd.exercise.chapter,
      section: exerciseToAdd.exercise.section,
      content_author: getExercisePropertyValue(this.cachedExercises, exerciseToAdd.exercise.id, 'author'),
      content_difficulty: getExercisePropertyValue(this.cachedExercises, exerciseToAdd.exercise.id, 'level'),
      content_id: exerciseToAdd.exercise.id.toString(),
      content_name: getExercisePropertyValue(this.cachedExercises, exerciseToAdd.exercise.id, 'title'),
      content_set: null,
      content_status: exerciseActivityInfo(exerciseToAdd.exercise.active),
      content_subtype: null,
      content_type: ContentType.EXERCISE,
      content_version: exerciseToAdd.exercise.variantId.toString(),
      education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
      event: EventName.ADD_TO_SET,
      method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
      page_path: this.$route.path,
      subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
    });
  }

  showPreviewGtmPush (cLogLevel: CLogLevel, exerciseVariantId: number): void {
    this.$piwik.push({
      event: EventName.SHOW_PREVIEW,
      c_log_level: cLogLevel,
      subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT),
      method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
      education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
      bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
      chapter: this.exercisePreView.exercise.chapter ?? null,
      section: this.exercisePreView.exercise.section ?? null,
      content_name: this.exercisePreView.exercise.title ?? null,
      content_status: exerciseActivityInfo(this.exercisePreView.exercise.active),
      content_version: exerciseVariantId.toString(),
      content_author: this.exercisePreView.exercise.author,
      content_difficulty: this.exercisePreView.exercise.level ?? null,
      content_subtype: null,
      content_type: ContentType.EXERCISE,
      page_path: this.$route.path,
      content_set: null,
      content_id: this.exercisePreView.exercise.id.toString()
    });
  }

  assignQueryParams (query: any): void {
    this.page = parseInt(getQueryParam(query.page, defaultPage.toString()));
    this.searchText = getQueryParam(query.search, defaultSearchText);
  }

  assignFacets (query: any) {
    const facets = getQueryParam(query.facets, '');
    if (facets) {
      const groupedFacets = facets.split('_').map(g => {
        const splittedGroup = g.split('-');
        const categoryName = splittedGroup[0];
        const ids = splittedGroup[1];
        return ids.split(',').map(id => ({ nameFiltr: categoryName, filterId: parseInt(id) }));
      });
      this.activeFacets = groupedFacets.flat();
    }
  }

  getQueryParams (): void {
    this.assignQueryParams(this.$route.query);
    this.assignFacets(this.$route.query);
  }

  setQueryParams (searchText = ''): void {
    const query: { [key: string]: string } = {};
    this.searchText = searchText;
    if (this.page !== defaultPage) query.page = this.page.toString();
    if (searchText !== defaultSearchText) query.search = searchText;
    if (this.activeFacets.length > 0) {
      const urlFacets: string[] = [];
      const groupedFacets = groupBy(this.activeFacets, 'nameFiltr');
      Object.keys(groupedFacets).forEach((key: any) => {
        const ids = groupedFacets[key].map((f: any) => f.filterId).join(',');
        urlFacets.push(`${key}-${ids}`);
      });
      query.facets = urlFacets.join('_');
    }
    this.$router.replace({ query }).catch(() => {});
  }

  updateFilters () {
    assignNumberOfExerciseToFilters(this.facets, 0, this.exercisesResponse.attribute);
    updateLevelFirst(this.facets, this.exercisesResponse.attribute);
    handleParentNodes(this.facets);
    this.filtersKey++;
  }

  async getFilteredExercises (searchText = '', resetPage = true) {
    if (this.isFetchingEnabled) {
      const activeFacets = localStorage.getItem('activeFacets');
      if (activeFacets) {
        const activeFacetsJson = JSON.parse(activeFacets);
        this.activeFacets = activeFacetsJson;
      }
      this.exercisesFetching();
      if (resetPage) this.page = 1;
      this.setQueryParams(searchText);
      const defaultOptions: ExercisesFilters = {
        categoryKey: this.categoryKey,
        p: this.page,
        limit: this.pageLimit,
        activeFacets: this.activeFacets,
        searchQuery: searchText,
        sort: this.sortField
      };
      const options: ExercisesFilters = {
        ...defaultOptions,
        ...this.customFilterOptions
      };
      this.isSearchCleared = this.searchText === '';
      try {
        await this.filterExercises(options);
        this.exercisesFetched();
        this.updateFilters();
      } catch (e) {
        this.exercisesFetchError();
      }
    }
  }

  clearFilterOptions () {
    this.isFetchingEnabled = false;
    this.searchText = '';
    this.isFetchingEnabled = true;
    this.getFilteredExercises();
  }

  findIndex () {
    return this.exercisePreView.exercise
      ? this.exercises.findIndex((exercise: Exercise) => exercise.id === this.exercisePreView.exercise.id)
      : 0;
  }

  changeExercise (step: number) {
    if (step > 0) {
      this.previewNextExercise();
    } else {
      this.previewPrevExercise();
    }
  }

  async previewPrevExercise () {
    const _tmpIdx = this.findIndex();
    if (this.page !== 0 && _tmpIdx === 0) {
      this.page = this.page - 1;
      await this.getFilteredExercises('', false);
      this.setExerciseToPreview(this.exercises[this.exercises.length - 1].id);
    } else {
      this.setExerciseToPreview(this.exercises[_tmpIdx - 1].id);
    }
  }

  async previewNextExercise () {
    const _tmpIdx = this.findIndex();
    if (this.page !== this.lastPage && _tmpIdx === 5) {
      this.page = this.page + 1;
      await this.getFilteredExercises('', false);
      this.setExerciseToPreview(this.exercises[0].id);
    } else {
      this.setExerciseToPreview(this.exercises[_tmpIdx + 1].id);
    }
  }

  setExerciseToPreview (id: number) {
    this.previewExercise(id);
  }

  async updateSelectedFilters (): Promise<void> {
    await this.getFilteredExercises();
  }

  @Emit() exercisesFetching () {}

  @Emit() exercisesFetched () {}

  @Emit() exercisesFetchError () {}
}
