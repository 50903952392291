
import { Component, Prop, PropSync, Emit, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ROLES } from '@/config';
import { EXERCISES_LIST, TOTAL_EXERCISES_FOUND } from '@/store/list';
import {
  ExerciseTile,
  SearchBar,
  Paginator
} from '@/shared/components';
import { Exercise } from '@/models';

const AppStore = namespace('AppStore');
const ExerciseStore = namespace('ExerciseStore');

@Component({
  name: 'exercise-list',
  components: {
    Paginator,
    ExerciseTile,
    SearchBar
  }
})
export default class ExerciseList extends Vue {
  @PropSync('searchText') searchQuery!: string;
  @PropSync('page') currentPage!: number;
  @Prop() lastPage!: number;
  @Prop() scaleFactor: number;

  @ExerciseStore.Getter(TOTAL_EXERCISES_FOUND) totalExercises: any;
  @ExerciseStore.Getter(EXERCISES_LIST) exercises: Exercise[];
  @AppStore.State('userRole') userRole: ROLES[];

  @Emit() changePagination () {}

  @Emit() search () {}

  @Emit() exerciseDeleted () {}

  @Emit() exercisesFetched () {}

  @Emit() exerciseEdit () {}

  @Emit() exercisePreviewOpen (exerciseVariantId: number) {
    return exerciseVariantId;
  }

  @Emit()
  addToTest (payload: any): any {
    return payload;
  }
}
