
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import cutTitleMixin from '@/mixins/cutTitleMixin';
import { namespace } from 'vuex-class';
import { GET_SCORE_COUNTER, GET_TEST_TIME, VARIANTS_ORDER } from '@/store/list';
import { TestModel } from '@/models';
import { GroupMap } from '@/helpers';
import EditableExercise from '../../../EditableExercise';
import { DIRECTIONS } from '@/config';
import { Scale } from '@/shared/components';
import GtTestPage from '@/shared/components/GtTest/GtTestPage.vue';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'PreviewContainerEditableTest',
  components: { GtTestPage, Scale, EditableExercise }
})
export default class PreviewContainerEditableTest extends mixins(cutTitleMixin) {
  @Prop() isOnePageViewOnly!: boolean;
  @Prop() scaleFactor: number;

  loadedExercisesCount = 0;
  allExercisesLoaded = false;

  @AppStore.State('step') currentStep!: any;
  @TestStore.State('currentTest') currentTest!: TestModel;
  @TestStore.Getter(GET_SCORE_COUNTER) totalScore!: number;
  @TestStore.Action(VARIANTS_ORDER) variantsOrder: any;
  @TestStore.Getter(GET_TEST_TIME) testTime!: number;

  get testPages () {
    return this.currentTest.variants && this.currentTest.variants[0] ? this.currentTest.variants[0].pages : [[]];
  }

  get flatTestPages () {
    return this.testPages.flat();
  }

  reorder () {
    this.variantsOrder({
      exerciseIndex: 0,
      pageIndex: 0,
      direction: DIRECTIONS.NONE
    });
  }

  handleAllExercisesLoad () {
    this.loadedExercisesCount++;
    if (this.loadedExercisesCount === this.flatTestPages.length) {
      this.allExercisesLoaded = true;
      this.reorder();
    }
  }

  getGroup (group: number) {
    return GroupMap.get(group);
  }
}
