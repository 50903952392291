
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ExitTestModal, FloatingFooter, NoExerciseInTest, StepOne, StepTwo } from './components';
import { namespace } from 'vuex-class';
import {
  CREATE_NEW_TEST,
  FETCH_TEST,
  FILTER_EXERCISES,
  IS_SPP,
  REMOVE_NOTIFICATION,
  SET_CATEGORY_KEY,
  SET_CURRENT_STEP,
  SET_DEFAULTS,
  SET_EXERCISES_DETAILS,
  SET_PAGE_ID,
  UPDATE_TEST,
  UPDATE_TEST_TIME,
  UPDATE_TOTAL_SCORE,
  USER_NOTIFY
} from '@/store/list';
import { EMPTY_TEST, GtPageTypes, ROLES } from '@/config';
import {
  AdditionalOptions,
  Exercise,
  ExerciseStoreResponse,
  Notification,
  Page,
  TestModel,
  TestType,
  TestTypeIdEnum
} from '@/models';
import { PageContent } from '@/shared/components';
import { LoadingStatus, LoadingStatusName } from '@/shared/models/loading';
import { deepClone } from '@/helpers/object-manipulation';
import { LayoutModel } from '@/store/layout-store/types';
import { RouteName } from '@/router/models';
import { Location, Route } from 'vue-router';
import { ExercisesFilters, Source } from '@/models/exercises';
import { handleTestTypeChange } from '@/helpers/test-helper';
import { isTeacherGroup } from '@/helpers/roles';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const ExerciseStore = namespace('ExerciseStore');
const LayoutStore = namespace('LayoutStore');
const TestStore = namespace('TestStore');

Component.registerHooks([
  'beforeRouteLeave',
  'beforeRouteUpdate',
  'beforeRouteEnter'
]);

interface exitModalConfig {
  url: string;
  route: any;
}

@Component({
  name: 'TestComposer',
  components: {
    ExitTestModal,
    FloatingFooter,
    NoExerciseInTest,
    PageContent,
    StepOne,
    StepTwo
  }
})
export default class TestComposer extends Vue {
  copiedTestNotification: Notification | null = null;
  exitRoute: Location;
  initialTestState = '';
  isTestPreviewOpen = false;
  isExitTestModalVisible = false;
  isNoExerciseModalShown: boolean = false;
  isPageLeavePossible = false;
  isRecalculateAlertDisabled = false;
  isViewReady = false;
  loadingStatus: LoadingStatusName = LoadingStatus.INCOMPLETE;
  testTypes: TestType[] = [];

  async beforeRouteEnter (to: Route, from: Route, next: Function) {
    if (from.name === RouteName.TEST_DATABASE) {
      localStorage.removeItem('activeFacets');
    }
    next();
  }

  @AuthStore.State('isUserLogged') isUserLogged: string;
  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('step') currentStep!: any;
  @AppStore.State('userRole') userRole: ROLES[];
  @ExerciseStore.State('exercises') exercises!: ExerciseStoreResponse;
  @LayoutStore.State('layout') layout!: LayoutModel;
  @TestStore.State('currentTest') currentTest!: TestModel;

  isTestChanged (): boolean {
    return this.initialTestState !== JSON.stringify(this.currentTest);
  }

  get testPages () {
    return this.currentTest && this.currentTest.variants[0] ? this.currentTest.variants[0].pages : [[]];
  }

  @Watch('currentTest', { deep: true })
  cacheUpdateHandler () {
    this.getScore();
  }

  @Watch('$route.params.testTypeId', { immediate: true })
  async testTypeChanged (newVal: string) {
    if (newVal) {
      this.handleTestType(newVal, this.currentTest);
      this.updateTest(this.currentTest);
    }
  }

  async created (): Promise<void> {
    this.setPageId(GtPageTypes.TEST_DATABASE);
    const categoryKey = this.$route.params.categoryKey.replace(/,/g, '/');
    if (categoryKey !== this.categoryKey) {
      this.setCategoryKey(categoryKey);
      await this.setDefaults();
    }
    this.setCurrentStep(1);
    await this.initializeTest();
    this.isViewReady = true;
  }

  mounted (): void {
    window.onbeforeunload = this.handlePageUnload;
  }

  destroyed (): void {
    window.onbeforeunload = null;
    this.removeNotification(this.copiedTestNotification);
  }

  beforeRouteLeave (to: Location, from: Route, next: Function): void {
    if (!this.isPageLeavePossible && !sessionStorage.getItem('testInEdition')) {
      this.isPageLeavePossible = true;
      if (this.isTestChanged()) {
        this.exitRoute = to;
        this.isExitTestModalVisible = true;
      } else {
        next();
      }
    } else {
      next();
    }
  }

  beforeRouteUpdate (to: any, from: any, next: any) {
    if (to.hash === '#testPreviewOpened') {
      next();
    }

    if (from.hash === '#testPreviewOpened') {
      this.isTestPreviewOpen = false;
      next();
    }
    next();
  }

  onExitModalConfirm (): void {
    this.isPageLeavePossible = true;
    this.isExitTestModalVisible = false;
    this.$router.push(this.exitRoute);
  }

  @AppStore.Mutation(SET_CURRENT_STEP) setCurrentStep!: any;
  @AppStore.Mutation(SET_PAGE_ID) setPageId: (id: GtPageTypes) => void;
  @AppStore.Mutation(SET_CATEGORY_KEY) setCategoryKey?: any;
  @AppStore.Action(SET_DEFAULTS) setDefaults: any;
  @AppStore.Action(USER_NOTIFY) notify: any;
  @AppStore.Mutation(REMOVE_NOTIFICATION) removeNotification: (n: Notification | null) => void;
  @AppStore.Getter(IS_SPP) isSpp: boolean;
  @TestStore.Mutation(CREATE_NEW_TEST) createTest!: any;
  @TestStore.Action(FETCH_TEST) getTest!: any;
  @TestStore.Action(UPDATE_TEST_TIME) updateTestTime!: () => void;
  @TestStore.Mutation(UPDATE_TOTAL_SCORE) setScore: any;
  @TestStore.Mutation(UPDATE_TEST) updateTest: (test: TestModel) => void;
  @ExerciseStore.Action(FILTER_EXERCISES) loadExercises: (exerciseFilters: ExercisesFilters) => Promise<void>;
  @ExerciseStore.Action(SET_EXERCISES_DETAILS) setExercisesDetails: (exercises: Exercise[]) => Promise<void>;

  async initializeTest (): Promise<void> {
    const routeName = this.$route.name;
    let test: TestModel = deepClone(EMPTY_TEST);
    test.categoryKey = this.categoryKey;
    this.handleTestType(this.$route.params.testTypeId, test);
    const testInEdition = JSON.parse(`${sessionStorage.getItem('testInEdition')}`);
    sessionStorage.removeItem('testInEdition');
    let hasUserAccess = true;
    if (testInEdition) {
      test = testInEdition;
    } else if (routeName === RouteName.TEST_EDITOR) {
      const testId = this.$route.params.testId;
      test = await this.getTest(testId);
      this.initialTestState = JSON.stringify(test);
      if (!test.canDelete) {
        hasUserAccess = false;
      }
    } else {
      const testFromExercises = JSON.parse(`${sessionStorage.getItem('testFromExercises')}`);
      const testToClone = this.$route.query.testToClone;
      this.initialTestState = JSON.stringify(test);
      if (testToClone) {
        this.copiedTestNotification = await this.notify({
          msg: this.$tc('NOTIFICATIONS.test_copied'),
          type: 'success',
          permanent: true
        });
        test = await this.getTest(testToClone);
        test.title = `${test.title} (${this.$tc('COMMON.copy')})`;
        const shouldOverWriteCloneIdWithTestId = isTeacherGroup(this.userRole) && test.cloneId === 0 && test.author === Source.NOWA_ERA;
        test.cloneId = shouldOverWriteCloneIdWithTestId ? test.id : test.cloneId;
        delete test.id;
        const query = Object.assign({}, this.$route.query);
        delete query.testToClone;
        this.$router.replace({ query });
      } else if (testFromExercises) {
        this.isRecalculateAlertDisabled = true;
        sessionStorage.removeItem('testFromExercises');
        test.variants[0].pages[0].push(testFromExercises.exercise);
        this.notify(this.$tc('NOTIFICATIONS.exercise_was_attached_to_test'));
      } else {
        this.isRecalculateAlertDisabled = true;
      }
    }
    this.createTest(test);
    this.updateTestTime();
    this.updateExercisesCache();
    if (!hasUserAccess) {
      this.notify(this.$tc('NOTIFICATIONS.access_denied'));
      await this.$router.push({ name: RouteName.HOME });
    }
  }

  handleTestType (type: string | (string | null)[], test: TestModel): void {
    handleTestTypeChange(+type, test);
  }

  onCancel (): void {
    this.exitRoute = { name: RouteName.TEST_DATABASE };
    if (this.isTestChanged()) {
      this.isExitTestModalVisible = true;
    } else {
      this.$router.push({ name: RouteName.TEST_DATABASE });
    }
    this.isPageLeavePossible = true;
  }

  onSave (): void {
    this.initialTestState = JSON.stringify(this.currentTest);
  }

  onSaveAndExit (): void {
    this.isPageLeavePossible = true;
    this.$router.push(this.exitRoute);
  }

  onExitModalClose (): void {
    this.isPageLeavePossible = false;
    this.isExitTestModalVisible = false;
  }

  handlePageUnload (e: any): null | void {
    if (this.isTestChanged()) {
      e.preventDefault();
      (e || window.event).returnValue = null;
      return null;
    }
  }

  updateExercisesCache () {
    const cacheExercisesIds = this.testPages
      .reduce((acc: any, val: any) => acc.concat(val), [])
      .map((exercise: any) => parseInt(exercise.id, 10));
    if (cacheExercisesIds.length) {
      this.setExercisesDetails(cacheExercisesIds);
    }
  }

  getScore () {
    const pages = this.testPages;
    let counter = 0;
    for (let i = 0; i < pages.length; i++) {
      const page: Page[] = pages[i];
      for (let j = 0; j < page.length; j++) {
        counter += page[j].score;
      }
    }
    this.setScore(counter);
  }

  exercisesFetching () {
    this.loadingStatus = LoadingStatus.INCOMPLETE;
  }

  exercisesFetched () {
    const testCopy = this.currentTest;
    testCopy.earlySchoolEducation = this.exercises.earlySchoolEducation;
    this.createTest(testCopy);
    this.initialTestState = JSON.stringify(testCopy);
    this.loadingStatus = LoadingStatus.COMPLETE;
  }

  exercisesFetchError () {
    this.loadingStatus = LoadingStatus.ERROR;
  }

  displayNoExerciseModal (): void {
    this.isNoExerciseModalShown = true;
  }

  closeNoExerciseModal () {
    this.isNoExerciseModalShown = false;
  }

  onTestRecalculate (): void {
    this.testTypes = this.getTestTypes(this.currentTest.additionalOptions as AdditionalOptions);
    this.handleTestType(this.$route.params.testTypeId, this.currentTest);
  }

  getTestTypes (additionalOptions: AdditionalOptions): TestType[] {
    const mappedTypesInArr = Object.entries(additionalOptions.type).map(([key, value]) => {
      return {
        id: Number(key),
        text: value
      };
    });
    return this.isSpp ? mappedTypesInArr.filter((type: TestType) => type.id !== TestTypeIdEnum.WORK_CARD) : mappedTypesInArr;
  }

  hidePreview () {
    this.isTestPreviewOpen = false;
    this.$router.push({ name: this.$route.name as string, hash: '' });
  }

  openPreview () {
    this.isTestPreviewOpen = true;
  }
}
