
import { Component, Emit, Vue, Prop, PropSync } from 'vue-property-decorator';
import { GroupMap } from '@/helpers';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

@Component({
  name: 'remove-group-modal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class RemoveGroupModal extends Vue {
  @PropSync('active') value: boolean;
  @Prop() groupIndex!: number;

  get group (): string {
    return GroupMap.get(this.groupIndex) || '';
  }

  @Emit()
  removeGroup (index: number) {
    return index;
  }

  @Emit() close () {}

  removeItem () {
    this.removeGroup(this.groupIndex);
    this.close();
  }
}
