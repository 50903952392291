
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SET_CURRENT_STEP } from '@/store/list';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

const AppStore = namespace('AppStore');
@Component({
  name: 'ExitTestModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class ExitTestModal extends Vue {
  @PropSync('active') value: boolean;

  @AppStore.Mutation(SET_CURRENT_STEP) setStep!: any;

  @Emit()
  close (): void {}

  @Emit()
  confirm (): void {}
}
