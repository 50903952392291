
import { Component, Emit, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PreviewContainer from './PreviewContainer';
import ExerciseList from './ExerciseList';
import { ExercisePreviewModal, RecursionTree } from '@/shared/components';

import { SEARCH_MODES } from '@/config';
import { mixins } from 'vue-class-component';
import ExerciseListMixin from '@/mixins/ExerciseListMixin';
import { TestModel } from '@/models';
import { DISPLAY_RECREATED, EXERCISES_ADD, USER_NOTIFY } from '@/store/list';
import { LoadingStatus } from '@/shared/models/loading';
import { ExerciseToAdd } from '@/models/exercises';
import { CLogLevel } from '@/models/gtm';
import FiltersNew from '@/shared/components/FiltersNew/FiltersNew.vue';
import { MenuModel } from '@/store/layout-store/types';
import GtMenu from '@/views/Generator/components/GtMenu/GtMenu.vue';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'StepOne',
  components: {
    GtMenu,
    ExerciseList,
    ExercisePreviewModal,
    FiltersNew,
    PreviewContainer,
    RecursionTree
  }
})
export default class StepOne extends mixins(ExerciseListMixin) {
  @Prop() currentTest!: TestModel;
  @Prop() loadingStatus: LoadingStatus;
  @Prop() isTestPreviewOpen: boolean;
  @Prop() step: number;
  filterScaleFactor = 0.85;
  customFilterOptions = { activity: '1' };

  mode = SEARCH_MODES;

  @AppStore.State('lastStep') lastStep: number;
  @AppStore.State('isFiltersActive') isFiltersActive: boolean;
  @LayoutStore.State('menu') menu: MenuModel;

  async created () {
    const query = this.$route.query;
    if (query.search && typeof query.search === 'string') this.searchText = query.search;
    if (query.filters && typeof query.filters === 'string') this.activeFacets = JSON.parse(query.filters);
    this.exercisePreViewOpened = false;
    await this.getFilteredExercises('', false);
    this.updateFilters();
  }

  @TestStore.Action(EXERCISES_ADD) addExercise: (exerciseToAdd: ExerciseToAdd) => void;
  @AppStore.Action(USER_NOTIFY) notify: any;
  @AppStore.Mutation(DISPLAY_RECREATED) recreatedGroups: any;

  addToTest (payload: ExerciseToAdd): void {
    this.addExercise(payload);
    this.notify(this.$tc('NOTIFICATIONS.exercise_was_attached_to_test'));
    if (this.lastStep === 2) {
      this.recreatedGroups();
    }
    this.addToSetGtmPush(payload);
  }

  closeExercisePreviewModal ():void {
    this.exercisePreViewOpened = false;
  }

  exercisePreviewOpen (exerciseVariantId: number) {
    this.showPreviewGtmPush(CLogLevel.CONTENT, exerciseVariantId);
  }

  @Emit() hidePreview () {}
}
