
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

@Component({
  name: 'NoExerciseInTest',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class NoExerciseInTest extends Vue {
  @PropSync('active') value: boolean;

  @Emit()
  close () {}
}
