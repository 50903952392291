
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_TEST_GROUP, UPDATE_TEST } from '@/store/list';
import RemoveGroupModal from './RemoveGroupModal';
import FinalizeEditableTest from './FinalizeEditableTest';
import FinalizeTestTabs from './FinalizeTestTabs';
import { TestModel } from '@/models';
import { TestGroupChangeEvent } from '@/models/tests';
import { deepClone } from '@/helpers/object-manipulation';

const TestStore = namespace('TestStore');

@Component({
  name: 'FinalizeTestContainer',
  components: {
    FinalizeEditableTest,
    RemoveGroupModal,
    FinalizeTestTabs
  }
})
export default class FinalizeTestContainer extends Vue {
  @Prop() isRedactorExamOrNsml!: boolean;

  removeGroupIndex = 0;
  displayRemoveGroupModal = false;
  leftGroupIndex = 0;
  rightGroupIndex = 1;

  @TestStore.State('currentTest') currentTest!: TestModel;

  get isRightGroupShown (): boolean {
    return this.currentTest.variants.length > 1;
  }

  get groupsClasses (): any[] {
    return ['gt-finalize-test-container__groups', { 'gt-finalize-test-container__groups--single': !this.isRightGroupShown }];
  }

  @Watch('currentTest')
  currentTestChangeHandler (nTest: TestModel, oTest: TestModel) {
    if (oTest.variants.length !== nTest.variants.length) {
      if (nTest.variants.length === 1) {
        this.leftGroupIndex = 0;
        this.rightGroupIndex = -1;
      } else {
        this.leftGroupIndex = nTest.variants.length - 2;
        this.rightGroupIndex = nTest.variants.length - 1;
      }
    }
  }

  mounted (): void {
    this.changeGroupPreview(true, 0);
    this.changeGroupPreview(false, 1);
  }

  @TestStore.Mutation(UPDATE_TEST) updateTest!: any;
  @TestStore.Action(ADD_TEST_GROUP) addTestGroup!: any;

  @Emit() removeExercise () {}

  @Emit()
  async addGroup (): Promise<void> {
    await this.addTestGroup();
  }

  changeGroupPreview (left: boolean, group: number): void {
    if (left) {
      this.leftGroupIndex = group;
    } else {
      this.rightGroupIndex = group;
    }
  }

  removeGroupModal (index: number): void {
    this.removeGroupIndex = index;
    this.displayRemoveGroupModal = true;
  }

  @Emit()
  removeGroup (index: number) {
    const test: any = deepClone(this.currentTest);
    test.variants.splice(index, 1);
    this.updateTest(test);
  }

  closeRemoveGroupModal (): void {
    this.displayRemoveGroupModal = false;
  }

  switchGroup (obj: TestGroupChangeEvent): void {
    this.changeGroupPreview(obj.isLeftPage, obj.index);
  }
}
